import React, { useEffect, useState } from 'react'
import ModalAsyncWait from 'components/modal/ModalAsyncWait'
import PropTypes from 'prop-types'

const AsyncModalContext = React.createContext({})

export function AsyncModalProvider({ children }) {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState('Lorem ipsum')

  useEffect(() => {
    document.body.style.overflow = 'scroll'
  }, [show])

  return (
    <AsyncModalContext.Provider value={{ show: setShow, setMessage }}>
      <ModalAsyncWait show={show} message={message} />
      {children}
    </AsyncModalContext.Provider>
  )
}

AsyncModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default AsyncModalContext
