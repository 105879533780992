import { toast } from 'react-toastify'

export const TOAST_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARN: 'warn',
}

const getToast = (type) => {
  switch (type) {
    case TOAST_TYPES.INFO:
      return toast.info

    case TOAST_TYPES.SUCCESS:
      return toast.success

    case TOAST_TYPES.ERROR:
      return toast.error
    case TOAST_TYPES.WARN:
      return toast.warn

    default:
      return toast.info
  }
}

export const showToast = (
  toastType,
  text = 'Toast',
  config = { position: 'top-right', lifetime: 4000 },
) => {
  const { position, lifetime } = config

  const toastFunc = getToast(toastType)
  toastFunc(text, {
    position,
    autoClose: lifetime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  })
}
