import { useTranslation } from 'react-i18next'
import LogoInduco64 from 'assets/images/logos/ic-pers-logo-64x64.png'

const LogoControlPresencia = () => {
  const { t } = useTranslation()
  return (
    <a className="text-decoration-none" href="https://inducontrol.com/es/">
      <div className="rounded px-2 py-1" style={{ backgroundColor: 'white' }}>
        <div className="d-flex align-items-center gap-2">
          <div>
            <img alt="ic-pers" src={LogoInduco64} height="48px" width="48px" />
          </div>
          <div className="d-flex flex-column justify-content-end">
            <h5
              style={{ fontFamily: 'Graphik-semibold', color: '#618073' }}
              className="text-bold mb-0 p-0"
            >
              {t('header.controlpres').toUpperCase()}
            </h5>
            <small style={{ fontFamily: 'Graphik-regular' }}>by Inducontrol</small>
          </div>
        </div>
      </div>
    </a>
  )
}

export default LogoControlPresencia
