import React, { Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import { useRoutes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './fonts/fonts.css'
import 'react-toastify/dist/ReactToastify.css'
import Themeroutes from './routes/Router'
import ThemeSelector from './layouts/theme/ThemeSelector'
import Loader from './layouts/loader/Loader'

import './i18n'

const App = () => {
  const routing = useRoutes(Themeroutes)
  const direction = useSelector((state) => state.customizer.isRTL)
  const isMode = useSelector((state) => state.customizer.isDark)

  return (
    <div
      className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
      dir={direction ? 'rtl' : 'ltr'}
    >
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeSelector />
      {routing}
    </div>
  )
}

const AppSuspense = () => {
  return (
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  )
}

export default AppSuspense
