import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AsyncModalProvider } from 'components/modal/AsyncModalContext'
import AppErrorFallback from 'components/AppErrorFallback'
import { injectStore } from 'service/APIHttpClient'
import { store } from './store/Store'

import reportWebVitals from './reportWebVitals'

import App from './App'
import './data'
import 'fetchcustom'

injectStore(store)

const root = createRoot(document.getElementById('root'))
root.render(
  <AsyncModalProvider>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <AppErrorFallback>
          <App />
        </AppErrorFallback>
      </BrowserRouter>
    </Provider>
  </AsyncModalProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
