import { Col, Row, Container, Button, Form, Card, CardBody, FormGroup, Input } from 'reactstrap'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { LoginFailure, LoginRequest, LoginSuccess } from 'store/AuthenticationSlice'
import AuthService from 'service/AuthService'
import { useTranslation } from 'react-i18next'
import LogoControlPresencia from 'components/LogoControlPresencia'

const LoginPage = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const [loginError, setLoginError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [cred, setCreds] = useState({
    username: '',
    password: '',
  })

  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const handleInputChange = ({ target }) => {
    const { name, value } = target
    setCreds({ ...cred, [name]: value })
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    dispatch(LoginRequest())

    AuthService.login(cred)
      .then((response) => {
        //console.log(response)
        const { data, message } = response

        if (data) {
          const { accessToken } = data
          if (accessToken) {
            localStorage.setItem('user', JSON.stringify(data))
            dispatch(LoginSuccess(data))
          } else {
            const msg = 'Missing access token'
            console.error(msg)
            dispatch(LoginFailure({ message: msg }))
            setLoginError(true)
            setErrorMessage(msg)
          }
        } else {
          dispatch(LoginFailure(message))
          setErrorMessage(message)
          setLoginError(true)
        }
      })
      .catch((error) => {
        const { message } = error
        dispatch(LoginFailure({ message }))
        setLoginError(true)
        setErrorMessage(message)
      })
  }

  const { REACT_APP_CLIENT_LOGO, REACT_APP_CLIENT_NAME, PUBLIC_URL } = process.env

  const logoUrl = `${PUBLIC_URL}/clients/${REACT_APP_CLIENT_LOGO}`

  return isAuthenticated ? (
    <Navigate to="/" />
  ) : (
    <section className="vh-100">
      <Container className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col className="col-12 col-md-8 col-lg-6 col-xl-5">
            <Card className="shadow-2-strong border rounded">
              <CardBody className="p-5">
                {!REACT_APP_CLIENT_LOGO ? null : (
                  <div className="my-3 d-flex justify-content-center">
                    <div className="w-50">
                      <img className="mw-100" src={logoUrl} alt={REACT_APP_CLIENT_NAME} />
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-center py-3">
                  <LogoControlPresencia />
                </div>

                {loginError ? (
                  <Row className="mb-2">
                    <h5 className="text-center text-danger">{errorMessage}</h5>
                  </Row>
                ) : null}

                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      name="username"
                      placeholder={t('login.username')}
                      type="text"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      name="password"
                      placeholder={t('login.password')}
                      type="password"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <Button className="btn btn-primary w-100" type="submit">
                    {t('login.loginbtn')}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default LoginPage
