import { ErrorBoundary } from 'react-error-boundary'
import { Col, Row, Container, CardBody, Card, Button, Input, FormGroup, Label } from 'reactstrap'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Clipboard } from 'react-feather'
import copyToClipboard from 'utils/clipboard'

const Fallback = ({ error, resetErrorBoundary, info }) => {
  Fallback.propTypes = {
    error: PropTypes.object,
    info: PropTypes.object,
    resetErrorBoundary: PropTypes.func,
  }

  return (
    <section className="vh-100">
      <Container className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col className="col-6 min-w">
            <Card className="shadow-2-strong border rounded">
              <CardBody className="p-4" style={{ minHeight: '50vh' }}>
                <div className="mb-3 flex-column justify-content-center">
                  <h2 className="text-danger mb-3">Unexpected error!</h2>
                  <h5 className>{`Message: ${error?.message}`}</h5>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="message">Stack trace:</Label>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          copyToClipboard(
                            error ? `{message: ${error.message}, stack: ${error.stack}}` : '',
                          )
                        }}
                      >
                        <Clipboard />
                      </span>
                    </div>

                    <Input
                      id="message"
                      type="textarea"
                      disabled
                      name="message"
                      rows="15"
                      value={error ? error.stack : ''}
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-center">
                    <Button onClick={resetErrorBoundary}>Confirm</Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

const AppErrorFallback = ({ children }) => {
  AppErrorFallback.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  }

  const dispatch = useDispatch()

  const onError = (error, info) => {
    //Registrar error en algun sistema de logs
  }

  const onReset = (info) => {
    localStorage.clear()
  }

  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={onError} onReset={onReset}>
      {children}
    </ErrorBoundary>
  )
}

export default AppErrorFallback
