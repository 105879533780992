import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import CommonEN from 'assets/locales/common/common.en.json'
import CommonES from 'assets/locales/common/common.es.json'

import SidebarES from 'assets/locales/sidebar/sidebar.es.json'
import SidbarEN from 'assets/locales/sidebar/sidebar.en.json'

import CommonCAT from 'assets/locales/common/common.cat.json'
import SidebarCAT from 'assets/locales/sidebar/sidebar.cat.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: CommonEN,
        sidebar: SidbarEN,
      },
      es: {
        common: CommonES,
        sidebar: SidebarES,
      },
      ca: { common: CommonCAT, sidebar: SidebarCAT },
    },
    ns: ['common', 'order', 'sidebar'],
    defaultNS: 'common',
    lng: localStorage.getItem('lang') || 'ca',
    fallbackLng: 'ca', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
