import { createSlice } from '@reduxjs/toolkit'

const storageUser = localStorage.getItem('user')

const initialState = {
  user: !!storageUser && JSON.parse(storageUser),
  isFetching: false,
  isAuthenticated: !!storageUser,
  message: '',
}

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    LoginRequest: (state) => {
      state.isFetching = true
      state.isAuthenticated = false
    },
    LoginSuccess: (state, { payload }) => {
      state.isFetching = false
      state.isAuthenticated = true
      state.user = payload
      state.message = ''
    },
    LoginFailure: (state, { payload }) => {
      state.isFetching = false
      state.isAuthenticated = false
      state.message = payload
    },
    UpdateTokens: (state, { payload }) => {
      const { accesToken, refreshToken } = payload
      state.user.accesToken = accesToken
      state.user.refreshToken = refreshToken

      localStorage.setItem('user', JSON.stringify(state.user))
    },
    LogoutRequest: (state) => {
      localStorage.removeItem('user')
      return {}
    },
    //   LogoutSuccess: (state) => {
    //     console.log("logout success")
    //     state.isFetching = false;
    //     state.isAuthenticated = false;
    //     state.user = {};
    //   },
  },
})

export const {
  LoginRequest,
  LoginSuccess,
  LoginFailure,
  LogoutRequest,
  LogoutSuccess,
  UpdateTokens,
} = AuthenticationSlice.actions

export default AuthenticationSlice.reducer
