import { configureStore } from '@reduxjs/toolkit'
import AuthenticationReducer from 'store/AuthenticationSlice'
import CustomizerReducer from 'store/customizer/CustomizerSlice'

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    auth: AuthenticationReducer,
  },
})

export default store
