import { GenericModal } from 'components/modal/GenericModal'
import { Spinner } from 'reactstrap'
import PropTypes from 'prop-types'

const ModalAsyncWait = ({ show = false, message = 'Carregant' }) => {
  return (
    <GenericModal title="" size="xs" show={show}>
      <div className="d-flex flex-row gap-3 justify-content-center align-items-center">
        <h1>{message}</h1>
        <Spinner />
      </div>
    </GenericModal>
  )
}
ModalAsyncWait.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
}

export default ModalAsyncWait
