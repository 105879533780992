import { APIClient } from 'service/APIHttpClient'

const AuthSerivce = {
  async login({ username, password }) {
    try {
      const { data } = await APIClient.post('/auth/signin', { username, password })
      return { data }
    } catch (error) {
      const { response } = error
      const { message } = response.data
      return { message, data: null }
    }
    // const fetchURL = `${API.baseUrl}/auth/signin`
    // const response = await fetch(fetchURL, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ username, password }),
    // })
    // const res = await response.json()
    // return { data: res }
  },
}

export default AuthSerivce
