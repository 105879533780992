import Portal from 'components/modal/Portal'

import PropTypes from 'prop-types'
import { AlertCircle, Info, XCircle, CheckCircle, HelpCircle } from 'react-feather'
import { Button, ModalBody, Modal, ModalHeader, ModalFooter, Alert } from 'reactstrap'
import { useTranslation } from 'react-i18next'

export const MODAL_ICONS = {
  alert: AlertCircle,
  info: Info,
  warn: AlertCircle,
  error: XCircle,
  success: CheckCircle,
  help: HelpCircle,
}

//sizes : xs,sm,md,lg,xl

export const GenericModal = ({
  show = false,
  title = 'Atenció',
  acceptCb,
  closeCb,
  backCb,
  cancelCb,
  children,
  size,
  isConfirm = false,
}) => {
  GenericModal.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    acceptCb: PropTypes.func,
    backCb: PropTypes.func,
    cancelCb: PropTypes.func,
    closeCb: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    isConfirm: PropTypes.bool,
  }

  const { t } = useTranslation('common')

  const header = title ? (
    typeof title === 'string' ? (
      <ModalHeader> {title}</ModalHeader>
    ) : (
      title
    )
  ) : null

  return (
    <Portal>
      <Modal isOpen={show} centered size={size}>
        {header}
        <ModalBody>{children}</ModalBody>
        {acceptCb || cancelCb || closeCb ? (
          <ModalFooter>
            {backCb ? <Button onClick={backCb}>{t('buttons.back')}</Button> : null}

            {acceptCb ? (
              <Button
                color="primary"
                onClick={() => {
                  acceptCb()
                }}
              >
                {isConfirm ? t('buttons.confirm') : t('buttons.accept')}
              </Button>
            ) : null}

            {cancelCb ? (
              <Button
                color="secondary"
                onClick={(evt) => {
                  evt.preventDefault()
                  cancelCb()
                }}
              >
                {t('buttons.cancel')}
              </Button>
            ) : null}
            {closeCb ? (
              <Button
                color="secondary"
                onClick={(evt) => {
                  evt.preventDefault()
                  closeCb()
                }}
              >
                {t('buttons.close')}
              </Button>
            ) : null}
          </ModalFooter>
        ) : null}
      </Modal>
    </Portal>
  )
}
