import axios from 'axios'
import { TOAST_TYPES, showToast } from 'components/ToastUtils'
import { LogoutRequest, UpdateTokens } from 'store/AuthenticationSlice'
import debounce from 'just-debounce-it'

let store
export const injectStore = (_store) => {
  store = _store
}

const baseAPIURL = `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_BACKEND_APP_NAME}/api`

const axiosJWT = axios.create({ baseURL: baseAPIURL })
const axiosNoJWT = axios.create({ baseURL: baseAPIURL })

// Add a request interceptor
axiosJWT.interceptors.request.use(
  (config) => {
    const { accessToken } = JSON.parse(localStorage.getItem('user'))
    config.headers.Authorization = `Bearer ${accessToken}`
    config.baseURL = baseAPIURL
    return config
  },
  (error) => {
    console.error(`APIHttpClient Request Error:  ${error}`)
    return Promise.reject(error)
  },
)

const refreshAccessToken = async () => {
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  try {
    const { data } = await axios.post(`${baseAPIURL}/auth/refreshtoken`, {
      jwt: userData.accessToken,
      refreshToken: userData.refreshToken,
    })

    const { jwtToken, refreshToken } = data
    store.dispatch(UpdateTokens({ accessToken: jwtToken, refreshToken }))

    userData.accessToken = jwtToken
    userData.refreshToken = refreshToken
    localStorage.setItem('user', JSON.stringify(userData))
    return true
  } catch (error) {
    store.dispatch(LogoutRequest())
    return false
  }
}

const showToastNetworkError = debounce(() => {
  showToast(TOAST_TYPES.ERROR, 'Network error')
}, 500)

const showToastSessionExpired = debounce(() => {
  showToast(TOAST_TYPES.INFO, 'Session expired')
}, 500)

// Response interceptor for API calls

axiosJWT.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    const { response } = error
    if (!response) {
      showToastNetworkError()
    } else if (response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true
      const updated = await refreshAccessToken()
      if (updated) {
        return axiosJWT(originalRequest)
      }
      showToastSessionExpired()
    } // axios.defaults.headers.common.Authorization = 'Bearer ' + access_token

    return Promise.reject(error)
  },
)

const APIAuthClient = {
  get: axiosJWT.get,
  post: axiosJWT.post,
  put: axiosJWT.put,
  delete: axiosJWT.delete,
  patch: axiosJWT.patch,
}

const APIClient = {
  get: axiosNoJWT.get,
  post: axiosNoJWT.post,
  put: axiosNoJWT.put,
  delete: axiosNoJWT.delete,
  patch: axiosNoJWT.patch,
}

export { APIClient, APIAuthClient }
