import { lazy } from 'react'
import Loadable from 'layouts/loader/Loadable'
import LoginPage from 'views/auth/ViewLogin'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport, name) => {
  return new Promise((resolve, reject) => {
    const key = `retry-${name}-refreshed`

    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(key) || 'false')
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(key, 'false') // success so reset the refresh
        resolve(component)
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(key, 'true') // we are now going to refresh
          return window.location.reload() // refresh the page
        }
        reject(error) // // Default error behaviour as already tried refresh
        return null
      })
  })
}

const lazyWithRetry = (componentImport, name) => {
  return lazy(() => lazyRetry(componentImport, name))
}

/****Layouts*****/

const FullLayout = Loadable(lazyWithRetry(() => import('layouts/FullLayout'), 'FullLayout'))
/***** Pages ****/

const ViewFitxar = Loadable(lazyWithRetry(() => import('views/ViewFitxar'), 'ViewFitxades'))

/*****Routes******/

const AuthRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  return isAuthenticated ? (
    { ...children }
  ) : (
    <>
      <Navigate to="/login" />
    </>
  )
}

AuthRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

const ThemeRoutes = [
  {
    path: '/',
    element: (
      <AuthRoute>
        <FullLayout />
      </AuthRoute>
    ),
    children: [
      { path: '/', name: 'Fitxar', element: <ViewFitxar /> },
      //{ path: '/sample', name: 'Sample', exact: true, element: <Sample /> }
    ],
  },
  { path: '/login', element: <LoginPage /> },
]

export default ThemeRoutes
